import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/theme/index.css";

import echarts from 'echarts';
import 'echarts/map/js/world'
Vue.prototype.$echarts = echarts;
import "echarts-wordcloud";

import VCharts from 'v-charts';
Vue.use(VCharts)

import axios from 'axios';
Vue.prototype.$axios = axios;

import qs from 'qs'
Vue.prototype.$qs = qs

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
