<template>
  <div class="home">
    <header>
      <nav>
        <router-link class="logo" to="/" />
        <div class="logo_title">
          <span style="font-size:20px;">CGTF IPv6Rank</span>
          <router-link style="font-size:12px;letter-spacing:0.5px;color:white;text-align:left;padding-left:2px;" to="/" >6rank.cgtf.net</router-link>
          <!-- <span style="font-size:12px;letter-spacing:0.5px;">6rank.cgtf.net</span> -->
        </div>
        <div class="right">
          <div class="nav_item">
            <router-link class="address" to="/" >网站排行</router-link>
            <router-link class="route" to="/routeDeclare">路由排行</router-link>
          </div>
          <div class="basic">
            <button class="login">登录</button>
            <button class="regist">注册</button>
          </div>
        </div>
      </nav>
      <div class="caption">
        <span style="color: white; font-size:42px; letter-spacing:4px;">IPv6发展监测平台</span>
      </div>
      <div class="search">
        <div class="search_inupt">
          <input type="text" v-model="input" placeholder="请输入事件类型、自治域、前缀等关键词" >
          <button @click="search">
            <i class="el-icon-search"></i>
          </button>
        </div>
        <div class="hot">
          <div class="square">热门搜索</div>
          <div class="word" >
            <li v-for="(item, index) in hotKeys" :key="index">
              <a href="javaScript:void(0)">{{item}}</a>
            </li>
          </div>
        </div>
      </div>
    </header>
    <div class="content">
      <div class="row">
        <div class="block">
          <svg t="1629193995625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1762" width="60" height="65"><path d="M654.826667 117.333333l209.173333 209.173334V928h-704v-810.666667h494.826667zM586.666667 181.312h-362.666667V864h576v-469.333333h-213.333333V181.333333zM704 672v64H320v-64h384z m0-170.666667v64H320v-64h384z m-203.264-170.666666v64H320v-64h180.736z m276.928 0L650.666667 203.669333V330.666667h126.997333z" fill="#48aeed" p-id="1763"></path></svg>
          <div class="report">
            <span>2021 IPv6</span>
            <br>
            <span>路由发展报告</span>
          </div>
        </div>
        <div class="block">
          <svg t="1629193995625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1762" width="60" height="65"><path d="M654.826667 117.333333l209.173333 209.173334V928h-704v-810.666667h494.826667zM586.666667 181.312h-362.666667V864h576v-469.333333h-213.333333V181.333333zM704 672v64H320v-64h384z m0-170.666667v64H320v-64h384z m-203.264-170.666666v64H320v-64h180.736z m276.928 0L650.666667 203.669333V330.666667h126.997333z" fill="#48aeed" p-id="1763"></path></svg>
          <div class="report">
            <span>2021 IPv6</span>
            <br>
            <span>网络发展报告</span>
          </div>
        </div>
        <div class="block">
          <svg t="1629193995625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1762" width="60" height="65"><path d="M654.826667 117.333333l209.173333 209.173334V928h-704v-810.666667h494.826667zM586.666667 181.312h-362.666667V864h576v-469.333333h-213.333333V181.333333zM704 672v64H320v-64h384z m0-170.666667v64H320v-64h384z m-203.264-170.666666v64H320v-64h180.736z m276.928 0L650.666667 203.669333V330.666667h126.997333z" fill="#48aeed" p-id="1763"></path></svg>
          <div class="report">
            <span>2021 IPv6</span>
            <br>
            <span>APP发展报告</span>
          </div>
        </div>
        <div class="block">
          <svg t="1629193995625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1762" width="60" height="65"><path d="M654.826667 117.333333l209.173333 209.173334V928h-704v-810.666667h494.826667zM586.666667 181.312h-362.666667V864h576v-469.333333h-213.333333V181.333333zM704 672v64H320v-64h384z m0-170.666667v64H320v-64h384z m-203.264-170.666666v64H320v-64h180.736z m276.928 0L650.666667 203.669333V330.666667h126.997333z" fill="#48aeed" p-id="1763"></path></svg>
          <div class="report">
            <span>2021 IPv6</span>
            <br>
            <span>云服务商支持度报告</span>
          </div>
        </div>
        <div class="block">
          <svg t="1629193995625" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1762" width="60" height="65"><path d="M654.826667 117.333333l209.173333 209.173334V928h-704v-810.666667h494.826667zM586.666667 181.312h-362.666667V864h576v-469.333333h-213.333333V181.333333zM704 672v64H320v-64h384z m0-170.666667v64H320v-64h384z m-203.264-170.666666v64H320v-64h180.736z m276.928 0L650.666667 203.669333V330.666667h126.997333z" fill="#48aeed" p-id="1763"></path></svg>
          <div class="report">
            <span>2021 网络</span>
            <br>
            <span>互通发展报告</span>
          </div>
        </div>
      </div>
      <div class="row tab">
        <span>
          <i class="el-icon-s-data" style="font-size:23px;margin-right:5px;"></i>排行榜
        </span>
        <el-switch
          :class="{'is-checked':switch_value === 'globle'}"
          v-model="switch_value"
          active-color="#DodgerBlue"
          inactive-color="DodgerBlue"
          active-text="全球"
          inactive-text="国内"
          active-value="globle"
          inactive-value="domestic"
          @change="switchButton()">
        </el-switch>
      </div>
      <div class="row_rank">
        <div class="rank" v-for="item,index in rankList" :key="index">
          <div class="title">
            <!-- <img :src="require(`../assets/images/icons/png/${index}.png`)" style="width:22px;height:22px;"> -->
            <iframe :src="require(`../assets/images/icons/svg/${Object.keys(item)[0]}.svg`)" style="width:25px;height:20px;transform:scale(1.3)"></iframe>
            <span class="title-one">{{title[Object.keys(item)[0]]}}</span>
            <span v-if="Object.keys(item)[0]=='app_support_grade' || Object.keys(item)[0]=='domain_support_grade'" class="title-two">支持度</span>
            <span v-else-if="Object.keys(item)[0]=='ipv6_route' || Object.keys(item)[0]=='v6_assigned'" class="title-two">CIDR数量 (以/32计)</span>
            <span v-else-if="Object.keys(item)[0]=='v6_peer_count'" class="title-two">建立Peer数量</span>
            <span v-else-if="Object.keys(item)[0]=='ipv6_active_security'" class="title-two">安全性</span>
            <span v-else class="title-two">数量</span>
          </div>
          <div class="list">
            <div v-if="Object.values(item)[0].length == 0">
              <div style="padding-top:40%;font-size:25px;color:grey;">暂 无 数 据</div>
            </div>
            <div v-else>
              <li class="select_active" v-for="(item, index) in Object.values(item)[0]" :key="index" >
                <span v-if="index==0">
                  <span style="color: #C70039; font-weight:bold; width:20px;">{{index+1}}</span>
                </span>
                <span v-else-if="index==1">
                  <span style="color: #FF5733; font-weight:bold; width:20px;">{{index+1}}</span>
                </span>
                <span v-else-if="index==2">
                  <span style="color: #FFC300; font-weight:bold; width:20px;">{{index+1}}</span>
                </span>
                <span v-else>
                  <span class="index">{{index+1}}</span>
                </span>
                <template v-if="Object.keys(item).includes('app_name')">
                  <span class="item">{{item.app_name}}</span>
                  <span class="number">{{item.support_grade}}</span>
                </template>
                
                <template v-else-if="Object.keys(item).includes('v6Prefixes32Count')">
                  <span class="item" @click="toASdetail(item.asn_with_des)" style="cursor:pointer;">AS{{item.asn_with_des}}</span>
                  <span class="number">{{item.v6Prefixes32Count.toString().indexOf('.') >= 0 ? item.v6Prefixes32Count.toString().substring(0,item.v6Prefixes32Count.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.v6Prefixes32Count.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('name')">
                  <span :title='item.name' class="item">{{item.name}}</span>
                  <span class="number">{{item.v6prefixes_32num.toString().indexOf('.') >= 0 ? item.v6prefixes_32num.toString().substring(0,item.v6prefixes_32num.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.v6prefixes_32num.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('rir_asn_count')">
                  <span :title='item.country' class="item">{{item.country}} ({{codeCountry[item.country]}})</span>
                  <span class="number">{{item.rir_asn_count.toString().indexOf('.') >= 0 ? item.rir_asn_count.toString().substring(0,item.rir_asn_count.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.rir_asn_count.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('route_asn_count')">
                  <span :title='item.country' class="item">{{item.country}} ({{codeCountry[item.country]}})</span>
                  <span class="number">{{item.route_asn_count.toString().indexOf('.') >= 0 ? item.route_asn_count.toString().substring(0,item.route_asn_count.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.route_asn_count.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('v6_route_asn_count')">
                  <span :title='item.country' class="item">{{item.country}} ({{codeCountry[item.country]}})</span>
                  <span class="number">{{item.v6_route_asn_count.toString().indexOf('.') >= 0 ? item.v6_route_asn_count.toString().substring(0,item.v6_route_asn_count.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.v6_route_asn_count.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('v6PeerCount')">
                  <span class="item" @click="toASdetail(item.asn_with_des)" style="cursor:pointer;">AS{{item.asn_with_des}}</span>
                  <span class="number">{{item.v6PeerCount.toString().indexOf('.') >= 0 ? item.v6PeerCount.toString().substring(0,item.v6PeerCount.toString().indexOf('.')).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") : item.v6PeerCount.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}</span>
                </template>
                <template v-else-if="Object.keys(item).includes('domain')">
                  <span :title='item.domain' class="item">{{item.domain}}</span>
                  <span class="number">{{item.support_grade}}</span>
                </template>
              </li>
              <div class="toRankDetail" @click="todetail(Object.keys(item)[0])">
                More<img src="../assets/images/icons/more.png" style="height:16px;width:16px;">
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import Foot from "@/components/footer";
import code_country from '@/assets/code_country.json'

export default {
  name: 'Home',
  components: {
    Foot,
  },
  data() {
    return {
      input: '',
      domestic: '',
      globle: '',
      title: {
        'v6_assigned':'机构 IPv6地址分配',
        'ipv6_route':'IPv6 路由宣告',
        'country_v6_route_asn_count':'路由IPv6的AS数量',
        'country_route_asn_count':'路由AS数量',
        'country_rir_asn_count':'分配AS数量',
        'ipv6_website':'IPv6 网站',
        'app_support_grade':'APP IPv6支持度',
        'domain_support_grade':'网站 IPv6支持度',
        'ipv6_traffic':'IPv6 流量',
        'v6_peer_count':'IPv6 连接度',
        'ipv6_active_security': 'IPv6活跃地址安全性'
      },
      hotKeys: ["2001:da8::1","AS4134","中国电信","www.baidu.com","AS239311","中国科技网","www.sougou.com","AS239311","www.tencent.com","赛尔网络","www.meituan.com","华为云"],
      rankList: [],
      switch_value: 'globle',
      value1: true,
      value2: true,
      codeCountry: '',
      //isSelected: 'china',
    }
  },
  watch: {
    rankList() {

    }
  },
  created() {
    this.$axios({
      method: 'GET',
      url: 'http://6rank.cgtf.net/api/summary',
    }).then( res => {
      this.domestic = res.data.domestic
      this.globle = res.data.global
      this.rankList = this.globle;
      console.log(this.rankList)
      console.log(Object.values(this.rankList[0])[0])
    }),
    this.codeCountry = code_country
  },
  mounted() {
  },
  methods: {
    search() {
      this.$router.push({name:'Detail', params:{input:this.input}})
    },
    todetail(index) {
      this.$router.push({name:'RankDetail', params:{rankindex:index,title:this.title[index]}})
    },
    toASdetail(as) {
      this.$router.push({name:'Detail', params:{input:as}})
    },
    switchButton() {
      console.log(this.switch_value)
      if (this.switch_value === 'domestic') {
        this.toChina();
      } else {
        this.toWorld();
      }
    },
    toChina() {
      //this.isSelected = 'china'
      this.rankList = this.domestic
    },
    toWorld() {
      //this.isSelected = 'world'
      this.rankList = this.globle
    }
  }
}
</script>

<style scoped src="../assets/css/reset.css"></style>
<style lang="scss" scoped>
.home {
  height: 100%;
  min-width: 1248px;
}
header {
  height: 370px;
  //width: 100%;
  background-image: url('../assets/images/home_background2.jpg');
  background-size: cover;
  background-position: center;
  //background-color: #1373e2;
}
nav {
  height: 65px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 1248px;
  .logo {
    //display: inline-block;
    background: url('../assets/images/logo.png') no-repeat;
    background-size: 100%;
    height: 36px;
    width: 36px;
  }
  .logo_title {
    margin-left: 4px;
    color: white;
    text-align: left;
    span {
      display: block;
      text-align: left;
    }
  }
  .right {
    margin-left: auto;
    display: flex;
    font-size: 14px;
    .nav_item {
      vertical-align: middle;
      //text-align: center;
    }
    .route {
      margin-left: 25px;
    }
    .address, .route {
      width: 60px;
      padding: 3px 0;
    }
    a:link, :visited {
      display: inline-block;
      text-decoration: none;
      color: white;
    }
    .router-link-active:after {
      content: "";
      display: block;
      margin: auto;
      margin-top: 5px;
      width: 30px;
      height: 0px;
      border: 1px solid white;
      }
    .basic {
      margin-left: 40px;
      button {
        height: 25px;
        width: 43px;
        font-size: 13px;
        color: white;
        //padding: 1px 1px;
        border: transparent;
        background: rgba(33, 131, 197, 0.9);
      }
      button:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      button:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
.caption {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 40px;
}
.search {
  margin: 0 auto;
  width: 800px;
  .search_input {
    display: flex;
  }
  input {
    height: 43px;
    width: 90%;
    padding: 0;
    text-indent: 10px;
    outline: none;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    vertical-align: bottom;
  }
  input:focus {
    box-shadow: 0 0 8px rgb(85, 85, 85);
  }
  button {
    height: 43px;
    padding: 0;
    width: 10%;
    background-color: rgb(211, 139, 6);
    color: white;
    font-size: 20px;  //即可改变图标大小
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    vertical-align: bottom;
  }
  .hot {
    margin-top: 30px;
    display: flex;
    text-align: left;
    color: white;
  }
  .square {
    //flex: 0 0 auto; // 默认 0 1 auto，1为flex中的flex-shrink属性，1为开启收缩。或通过下面的方式
    flex: 0 0 59px;
    display: inline-block;
    font-size: 16px;
    width: 56px;
    height: 51px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 32px;
    padding-top: 6px;
    padding-left: 2px;
    text-align: center;
    letter-spacing: 5px;
    line-height: 23px;
    background: rgba(255, 255, 255, 0.16);
  }
  .word {
    //width: 572px;
    li {
      list-style: none;
      display: inline;
      font-size: 14.5px;
      a {
        display: inline-block;
        color: white;
        padding: 0 6px;
        line-height: 30px;
      }
    }
  }
}
.content {
  margin: 0 auto;
  width: 1248px;
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row:first-of-type {
    margin-top: 40px;
    margin-bottom: 5px;
  }
  .tab {
    margin-top: 30px;
    margin-bottom: 15px;
    span {
      font-size: 20px;
      i {
        vertical-align: text-bottom; // 把元素的底端与父元素字体的底端对齐。
      }
    }
    .el-switch /deep/ span {
      font-size: 15px;
    }
    .el-switch /deep/ .el-switch__core {
      background: DodgerBlue;
      border-color: DodgerBlue;
      border-radius: 12px;
      width: 47px;
      height: 22px;
    }
    .is-checked /deep/ .el-switch__core:after {
      margin-left: -19px;
    }
    .el-switch /deep/ .el-switch__core:after {
      width: 18.4px;
      height: 18.4px;
    }
    .el-switch /deep/ .el-switch__label.is-active {
      font-weight: bold;
    }
  }
  a:first-of-type {
    margin-right: 10px;
  }
  a:last-of-type {
    margin-left: 10px;
  }
  .active:after {
    content: "";
    display: block;
    margin: auto;
    margin-top: 7px;
    width: 55px;
    height: 0.5px;
    background: rgb(146, 146, 146);
    //color: rgb(95, 95, 95);
    border: 1px solid rgb(146, 146, 146);
  }
  .block {
    width: 18%;
    height: 70px;
    // border: 1px solid rgb(216, 216, 216);
    box-shadow: 0 0 6px rgb(216, 216, 216);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: left;
    .report {
      text-align: left;
    }
    span {
      //display: block;
      font-size: 14px;
      line-height: 23px;
    }
  }
  .row_rank {
    display: flex;
    flex-wrap: wrap;
  }
  .rank {
    width: 32%;
    margin: 1% 0;
    height: 433px;
    box-shadow: 0 0 6px rgb(216, 216, 216);
  }
  .rank:nth-of-type(3n+2) { // nth-of-type(3n+2)也是一样的,child只能是上一级的子元素
    margin: 1% 2% 1% 2%;
  }
}
.rank {
  .title {
    padding: 5% 20px 0 19px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    line-height: 24px;
    iframe {
      border: none;
    }
    .title-one {
      margin-right: auto;
      margin-left: 15px;
      font-size: 18px;
    }
    .title-two {
      color: rgb(109, 109, 109);
      margin-left: auto;
    }
  }
  .list {
    padding: 5% 20px 3% 18px;
  }
  li {
    list-style: none;
    height: 32.2px;
    // margin-bottom: 4.2%;
    font-size: 15.5px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    span {
      display: inline-block;
      line-height: 19px;
    }
    .index {
      font-weight: bold;
      color: rgb(158, 158, 158);
      width: 20px;
    }
    .item {
      margin-left: 24px;
      margin-right: 70px;
      white-space: nowrap;  // 规定段落中的文本不进行换行
      text-overflow: ellipsis;  // 显示省略号
      overflow: hidden;
    }
    .number {
      margin-left:auto;
    }
  }
  li:hover {
    background: rgb(247, 247, 247);
  }
  .toRankDetail {
    margin-top: 10px;
    text-align: right;
    cursor: pointer;
    img {
      vertical-align: text-bottom;
      margin-left: 3px;
    }
  }
}
</style>
